@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  background-color: rgba(209, 214, 197, 0.35);
  font-weight: 300;
}
#root, .App {
  height: 100vh !important;  
}
#main-body {
  display: flex;
  background-color: #edf1f2;
  height: 100%;
}
#Content {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 8px;
  scrollbar-width: thin;
}
.navbar {
  padding-left: 5rem;
  z-index: 1000;
  transition: padding 250ms;
}
.navbar .dropdown .dropdown-menu {
  margin-top: 25px !important;
}

#topnav .logo {
  width: 158px;
}
#topnav .navbar-nav a:hover, #topnav .navbar-nav svg:hover {  
  color: #000;
  font-weight: 500;
}
#topnav .navbar-nav svg {
  color: grey;
}
#topnav .navbar-nav .nav-item .active {
  font-size: 17px;
  font-weight: 500;
  color: navy !important;
}
 #topnav .navbar-nav .nav-item .active svg {
   color: navy;
 }

/* Top Navbar custom styles*/
@media (min-width: 992px) {
  .dropdown-menu .dropdown_item-1 {
    transform-origin: top center;
    animation: translateX 300ms 60ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-2 {
    transform-origin: top center;
    animation: translateX 300ms 120ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-3 {
    transform-origin: top center;
    animation: translateX 300ms 180ms ease-in-out forwards;
  }
}

@-moz-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-webkit-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-o-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.message-dropdown {
  margin-right: 50px;
}
.message-dropdown-menu {
  margin-top: 20px !important;
  width: 20rem;
}
.message-dropdown-menu .message-description {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.dropdown-list-image{
  margin-top: -10px;
}
.dropdown-list-image .rounded-circle {
  width: 40px;
  height: 40px;
}
.text-truncate {
  display: block;
  max-width: 220px;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-triangle-excl{
  margin-top: -3px;
}

.table-responsive {
  border-radius: 5px;
  border: 1px solid lightgrey;
}

#login-container {  
  padding: 40px;
}

#login-area {  
  margin-left: 8rem;
  margin-right: 8rem;
}

canvas {
  background: white;
  padding: 10px;
}
.pie-chart {
  max-height: 400px !important;
}
.action-icons {
  cursor: pointer;
}
.interlocutor-chat {
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: block;
}
.my-chat {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: block;
  background-color: rgba(209, 214, 197, 0.35);
}
.response-file-input {
  display: none;
}
.chat-file {
  max-width: 280px;
}


/* Responsive CSS */
@media (max-width: 576px) {
  
  #login-container {  
    padding: 30px;
  }
  #login-area {  
    margin: 0;
  }
  #login-main {
    width: 95% !important;
  }

}
.invoice-img {
  object-position: top;
  object-fit: cover;
  object-scale: none;
  max-height: 250px;
}
